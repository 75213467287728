export function title(string: string): string {
  try {
    return string
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ')
  } catch (error) {
    return string
  }
}

export function headline(string: string): string {
  return string
    .split(/(?=[A-Z])|_|-/)
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')
}

export function kebab(string: string): string {
  return string
    .split(/(?=[A-Z])|_|-|\.|\s/)
    .map((word) => word.toLowerCase())
    .join('-')
}

export function removeDuplicates(string: string, word: string): string {
  const regex = new RegExp(`\\b(${word})\\b\\s+\\1\\b`, 'gi')
  return string.replace(regex, '$1')
}

export function upper(string: string): string {
  return string.toUpperCase()
}
